













































































//@import url(); 引入公共css类
.caseVue .header {
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
